import { Heading, Image } from '@quality24/design-system';
import classNames from 'classnames';
import React from 'react';

import TypedText from '../../atoms/TypedText';
import useOnScreen from '../../../utils/useOnScreen';

import * as styles from './SolutionsTemplate.module.scss';

interface SolutionStepContent {
  className?: string;
  image: string;
  content: string;
  width?: number | string;
}

export interface Props {
  idx: number;
  step: SolutionStepContent;
}

/**
 * <SolutionsSection>
 */
const SolutionsSection: React.FunctionComponent<Props> = ({ idx, step }) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  return (
    <div
      key={step.content}
      ref={elementRef}
      className={classNames({
        visible: isVisible,
        right: idx % 2 === 0,
        left: idx % 2 !== 0,
      })}
    >
      <div className={styles.bgEffect} />

      <div className="container row flex-items-stretch gx-5 gy-3">
        <div
          className={classNames('col-12 col-lg-6 anim', {
            'anim-slide-left': idx % 2 === 0,
            'anim-slide-right': idx % 2 !== 0,
            visible: isVisible,
          })}
        >
          <Image
            className={styles.image}
            src={step.image}
            alt={step.content}
            style={{ minWidth: step.width }}
          />
        </div>

        <div
          className={classNames(
            'col-12 col-lg-6 p-5 px-lg-3 d-flex flex-items-center anim',
            {
              'anim-slide-left': idx % 2 !== 0,
              'anim-slide-right': idx % 2 === 0,
              visible: isVisible,
            },
          )}
        >
          <Heading
            as="h2"
            size="md"
            weight="bold"
            color={idx % 2 === 0 ? 'primaryDark' : 'tertiary'}
          >
            <TypedText template={step.content} />
          </Heading>
        </div>
      </div>
    </div>
  );
};

export default SolutionsSection;
