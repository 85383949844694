import { Heading, Text } from '@quality24/design-system';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import MainTemplate from '../MainTemplate';
import GradientIcon from '../../atoms/GradientIcon';
import InfoCard from '../../molecules/InfoCard';
import SolutionsSection from './SolutionsSection';
import useOnScreen from '../../../utils/useOnScreen';

import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import CircleGraphic from '../../../assets/graphic-2.inline.svg';
import BarsGraphic from '../../../assets/graphic-3.inline.svg';

import * as styles from './SolutionsTemplate.module.scss';

interface HeroContent {
  title: string;
  icon: FontAwesomeIconProps['icon'];
  subtitle: string;
  image: React.ReactNode;
}

interface CardContent {
  className?: string;
  icon: FontAwesomeIconProps['icon'];
  title: string;
  content: string;
}

interface SolutionStepContent {
  className?: string;
  image: string;
  content: string;
  width?: number | string;
}

export interface Props {
  hero: HeroContent;
  cards: CardContent[];
  steps: SolutionStepContent[];
}

const SolutionHero: React.FunctionComponent<HeroContent> = ({
  title,
  icon,
  subtitle,
  image,
}) => (
  <div className={classNames('container', styles.heroWrapper)}>
    <div className="d-flex flex-items-center gap-2">
      <GradientIcon className="p-2" icon={icon} />
      <Heading as="h1" size="lg1" weight="bold" color="white">
        {title}
      </Heading>
    </div>
    <Text className="mb-5" size="xl">
      {subtitle}
    </Text>

    {image}

    <SquareGraphic className={classNames(styles.graphic, styles.square)} />
    <CircleGraphic className={classNames(styles.graphic, styles.circle)} />
    <BarsGraphic className={classNames(styles.graphic, styles.bars)} />
  </div>
);

/**
 * <SolutionsTemplate>
 */
const SolutionsTemplate: React.FunctionComponent<Props> = ({
  hero,
  cards,
  steps,
}) => {
  const elementInfoRef = React.useRef<HTMLDivElement>(null);
  const isVisibleInfo = useOnScreen(elementInfoRef);

  return (
    <MainTemplate heroContent={<SolutionHero {...hero} />}>
      {/* Info card section */}
      <div
        ref={elementInfoRef}
        className={classNames(styles.infoWrapper, 'container row g-2 g-md-4')}
      >
        {cards.map((c) => (
          <div key={c.title} className="col-12 col-md-6 col-lg-3">
            <InfoCard
              key={c.title}
              className={classNames(
                'anim anim-bounce-in',
                {
                  visible: isVisibleInfo,
                },
                c.className,
              )}
              icon={c.icon}
              cardTitle={c.title}
              cardTitleColor="secondary"
            >
              {c.content}
            </InfoCard>
          </div>
        ))}
      </div>

      {/* solutions card section */}
      <div className={styles.solutionWrapper}>
        {steps.map((s, idx) => (
          <SolutionsSection idx={idx} step={s} />
        ))}
      </div>
    </MainTemplate>
  );
};

export default SolutionsTemplate;
