import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import useOnScreen from '../../../utils/useOnScreen';

const PATTERN = /{{(.+?)}}/;

const getDynamicText = (template: string) => {
  const parts = PATTERN.exec(template);
  if (!parts) return '';
  return parts[1] ?? '';
};

export interface Props {
  template: string;
}

const TypedText: React.FunctionComponent<Props> = ({ template }) => {
  const breakpoints = useBreakpoint();

  const variable = React.useMemo(() => getDynamicText(template), [template]);
  const [current, setCurrent] = React.useState('');

  const intervalRef = React.useRef<null | NodeJS.Timer>(null);

  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  React.useEffect(() => {
    if (!isEmpty(breakpoints) && !breakpoints.md) {
      setCurrent(variable);
    }
  }, [breakpoints, variable]);

  React.useEffect(() => {
    // Increments the number by 5% every 100ms
    intervalRef.current = setInterval(() => {
      if (!isVisible) return;

      setCurrent((prev) => {
        // Stop timer if value reached the expected number
        // @note: need to round the number due to it not being an integer
        if (current === variable) {
          clearInterval(intervalRef.current as NodeJS.Timer);
          return variable;
        }
        return variable.slice(0, prev.length + 1);
      });
    }, 100);

    return () => clearInterval(intervalRef.current as NodeJS.Timer);
  }, [intervalRef, isVisible, current, variable]);

  return <span ref={elementRef}>{template.replace(PATTERN, current)}</span>;
};

export default TypedText;
