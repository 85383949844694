import {
  faBell,
  faChartUser,
  faClipboardUser,
  faComment,
  faMessageSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { Image } from '@quality24/design-system';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { HeadFC } from 'gatsby';
import * as React from 'react';

import SEO from '../../components/containers/SEO';
import Video from '../../components/atoms/Video';
import SolutionsTemplate from '../../components/templates/SolutionsTemplate';

import frame from '../../assets/macbook-frame.webp';
import overlay from '../../assets/solutions/feedback-overlay.webp';
import poster from '../../assets/solutions/feedback-poster.webp';
import video from '../../assets/videos/vdp.mp4';
import videoWebm from '../../assets/videos/vdp.webm';

import heroImage from '../../assets/solutions/feedback0.webp';
import image2 from '../../assets/solutions/feedback2.webp';
import image3 from '../../assets/solutions/feedback3.webp';
import * as styles from './vozdopaciente.module.scss';

const FeedbackPage: React.FunctionComponent = () => {
  const breakpoints = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoints.lg === false, [breakpoints]);

  const HeroVideo = React.useMemo(
    () => (
      <div className={styles.laptop}>
        <Image className={styles.frame} src={frame} alt="laptop-frame" />
        <Image
          className={styles.mobileFrame}
          src={overlay}
          alt="mobile-frame"
        />
        <div className={styles.screen}>
          <Video
            className={styles.video}
            width={678}
            autoPlay={!isMobile}
            poster={poster}
            controls
            muted
            loop
          >
            <source src={videoWebm} type="video/webm" />
            <source src={video} type="video/mp4" />
            Painel voz do paciente
          </Video>
        </div>
      </div>
    ),
    [isMobile],
  );

  return (
    <SolutionsTemplate
      hero={{
        title: 'Voz do Paciente',
        subtitle: 'Aumente a conexão com os seus pacientes',
        icon: faMessageSmile,
        image: HeroVideo,
      }}
      cards={[
        {
          icon: faChartUser,
          title: 'Dashboard',
          content:
            'Comunicação simples e direta com todos os departamentos do hospital',
        },
        {
          icon: faBell,
          title: 'Alertas',
          content:
            'Atendimento ágil, único e exclusivo com os pacientes e acompanhantes',
        },
        {
          icon: faComment,
          title: 'Feedback do paciente',
          content:
            'Pesquisa de satisfação sem burocracia e mais agilidade ao SAC e Ouvidoria',
        },
        {
          icon: faClipboardUser,
          title: 'Gestão',
          content:
            'Dados e orientações sobre a jornada do paciente, conforme o perfil',
        },
      ]}
      steps={[
        {
          image: heroImage,
          content: 'Identifique problemas e gargalos em tempo real',
          width: '100%',
        },
        {
          image: image2,
          content:
            'Gere relatórios e indicadores sobre a qualidade do atendimento ao paciente/beneficiário',
          width: '100%',
        },
        {
          image: image3,
          content: 'Conecte, agilize e facilite a comunicação com o paciente',
        },
      ]}
    />
  );
};

export default FeedbackPage;

export const Head: HeadFC = () => (
  <SEO
    title="Voz do Paciente | Soluções para Hospitais"
    description="Aumente a conexão com os seus pacientes"
    image={heroImage}
  />
);
